"use client";

import React from "react";

import chooseImg from "../../styles/images/choose-img.png";
import {ourServices} from "../../libs/ourServices";

const WhyChooseUs: React.FC = () => {
  return (
      <>
        <div className="choose-ue-area">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div
                    className="choose-title"
                >
                  <span>Why Choose Us</span>
                  <h2>
                    Our services spectrum includes but is not limited to:
                  </h2>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-5">
                {ourServices.map((ourService) => (
                    <div className="single-features">
                      <span>
                        <i className={ourService.icon}></i>
                      </span>
                      <p>{ourService.title}</p>
                      <a href={"/service-details/" + ourService.id} className="read-more-icon">
                        <span className="flaticon-right-arrow"></span>
                      </a>
                    </div>
                ))}
              </div>

              <div className="col-lg-7">
                <div
                    className="choose-img"
                >
                  <img src={chooseImg} alt="Image" width={807} height={704} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default WhyChooseUs;
import "../styles/bootstrap.min.css";
import "../styles/animate.css";
import "../styles/boxicons.min.css";
import "../styles/flaticon.css";


// Global styles
import "../styles/style.css";
import "../styles/responsive.css";

import React from 'react';
import Navbar from "../components/Layouts/Navbar";
import Footer from "../components/Layouts/Footer";
import MainBanner from "../components/MainBanner";
import ScrollToTop from "../components/Layouts/ScrollToTop";
import AosAnimation from "../components/Layouts/AosAnimation";
import ContactForm from "../components/Contact/ContactForm";
import About from "../components/About";
import Pricing from "../components/Pricing/Pricing";
import ResolutionRoadmap from "../components/Services/ResolutionRoadmap";
import Services from "../components/Services/Services";

function Main() {
    return (
        <>
            <Navbar/>
            
            <section id="home"><MainBanner/></section>
            <section className="menu-area" id="about"><About/></section>
            <section className="menu-area" id="services">
                <Services/>
                <ResolutionRoadmap/>
            </section>
            <section className="menu-area" id="pricing"><Pricing/></section>
            <section className="menu-area" id="contact"><ContactForm/></section>

            <Footer/>

            <AosAnimation/>

            <ScrollToTop/>
        </>
    );
}

export default Main;

"use client";

import React, { useState } from "react";

import contactImg from "../../styles/images/contact-img.png";

interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState<string[] | null>(null);
  const [sendStatus, setSendStatus] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch("https://email-sender-promo.azurewebsites.net/api/SendContactEmail", {
      method: "POST",
      body: JSON.stringify(formData),
    })
        .then((response) => {
          if (!response.ok) {
            return response.json();
          }
        })
        .then((error) => {
          if (error) {
            setError(error);
            setSendStatus(false);
          } else {
            setError(null);
            setSendStatus(true);
            setFormData({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            });
          }
        })
        .catch((error) => console.error("Error:", error));
  };

  return (
    <>
      <div className="main-contact-area">
        <div className="container">
          <div className="section-title">
            <span>Contact Us</span>
            <h2>Drop us a message for any query</h2>
            <p>
              We will endeavour to answer your question as soon as possible.
            </p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="contact-wrap contact-pages mb-0">
                <div className="contact-form">
                  {(error && error.length > 0) && error.map(e => (<p className="text-danger">{e}</p>))}
                  {sendStatus && <p className="text-success">Message sent successfully</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                              type="text"
                              name="email"
                              placeholder="Email"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                              type="text"
                              name="phone"
                              placeholder="Phone number"
                              className="form-control"
                              value={formData.phone}
                              onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              className="form-control"
                              value={formData.subject}
                              onChange={handleChange}
                              required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                              name="message"
                              cols={30}
                              rows={6}
                              placeholder="Write your message..."
                              className="form-control"
                              value={formData.message}
                              onChange={handleChange}
                              required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-sm-12">
                        <button type="submit" className="default-btn btn-two">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="contact-img">
                <img src={contactImg} alt="Image" width={807} height={704} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

import React from "react";
import Navbar from "../components/Layouts/Navbar";
import Footer from "../components/Layouts/Footer";
import {useParams} from "react-router";
import PageBanner from "../components/Common/PageBanner";
import ServiceDetailsContent from "../components/Services/ServiceDetailsContent";
import AosAnimation from "../components/Layouts/AosAnimation";
import ScrollToTop from "../components/Layouts/ScrollToTop";

export default function ServiceDetails() {
    const {id} = useParams();
    return (
        <>
            <Navbar/>
            <PageBanner
              pageTitle="Service Details"
              homePageUrl="/"
              homePageText="Home"
              activePageText="Service Details"
            />

            <ServiceDetailsContent />

            <Footer/>

            <AosAnimation/>

            <ScrollToTop/>
        </>
    );
}

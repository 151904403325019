"use client";

import React from "react";
import {contractModels} from "../../libs/contractModels";

const ContractModels: React.FC = () => {
    return (
        <>
            <div
                className="row justify-content-center"
            >
                {contractModels.map((contractModel) => (
                    <div className="col-lg-4 col-md-6">
                        <div className="single-pricing">
                            <div className="pricing-top-heading">
                                <h3>{contractModel.title}</h3>
                            </div>

                            <ul>
                                {contractModel.features.map((feature) => (
                                    <li>
                                        <i className="bx bx-check"></i>
                                        <strong>{feature.title}:</strong>&nbsp;{feature.description}
                                    </li>
                                ))}
                            </ul>

                            <a href="#contact" className="default-btn">
                                Get Started
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ContractModels;

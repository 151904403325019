"use client";

import React, { useState, useEffect } from "react";
import { menus } from "../../libs/menus";

import logo from "../../styles/images/white-logo-esion-horizontal.png";
import {Link} from "react-router-dom";

const Navbar: React.FC = () => {
  const [menu, setMenu] = useState<boolean>(true);
  const [activeLink, setActiveLink] = useState<string>("home");
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const marginTop = 130;
      const scrollToY = section.getBoundingClientRect().top + window.scrollY - marginTop;
      window.scrollTo({ top: scrollToY, behavior: "smooth" });
    }
  }
  
  const determineActiveSection = () => {
    for(let i= menus.length -1; i >= 0; i--) {
      const section = document.getElementById(menus[i].link);
      if (section) {
        const rect = section.getBoundingClientRect();
        console.log(menus[i].link, rect.top, rect.bottom)
        if (rect.top > -200 && rect.top < 300) {
          setActiveLink(menus[i].link);
          break;
        }
      }
    }
  }
  
  useEffect(() => {
    if(window.location.hash) {
      let sectionId = window.location.hash.substring(1);
      scrollToSection(sectionId);
    }

    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 90) {
        elementId?.classList.add("is-sticky");
      } else {
        elementId?.classList.remove("is-sticky");
      }
    });
    
    const handleScroll = () => {
      if(window.scrollY > 300){
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      determineActiveSection();
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";
      
  return (
      <div id="navbar" className="navbar-area fixed-top">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container">
            <Link to="/#home" className="navbar-brand" onClick={() => scrollToSection("home")}>
              <img src={logo} alt="logo" width={150} height={37} />
            </Link>
            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                {menus.map((menuItem) => (
                    <li className="nav-item" key={menuItem.label} onClick={() => scrollToSection(menuItem.link)}>
                      <Link to={"/#" + menuItem.link}  className={activeLink === menuItem.link ? "active" : "" }>
                        {menuItem.label}
                      </Link>
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
  );
};

export default Navbar;

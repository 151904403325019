"use client";

import React from "react";

import offerImg from "../../styles/images/offer1.png";

const ResolutionRoadmap: React.FC = () => {
  return (
    <>
      <div className="industries-serve-area pt-70">
        <div className="container">
          <div className="section-title">
            
            <h2>Our way of success</h2>
            <p>
              Are you ready to unlock valuable insights from your business data? At ESION, 
              we leverage cutting-edge tech and deep expertise to transform raw data into tangible value for your business.
            </p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 col-md-6">
                  <div className="single-industries">
                    <i className="flaticon-machine-learning"></i>
                    <h3>Pre-study and Problem Identification</h3>
                    <span>Free initial study limited to 16 hours.</span>

                    <div className="down-icon">
                      <span className="bx bx-chevrons-down"></span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6">
                  <div className="single-industries">
                    <i className="flaticon-engineer"></i>
                    <h3>Solution Architecture Design</h3>
                    <span>Utilizing open-source applications for cost-effective solutions or licensed-based software as needed.</span>

                    <div className="down-icon">
                      <span className="bx bx-chevrons-down"></span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6">
                  <div className="single-industries">
                    <i className="flaticon-success"></i>
                    <h3>Estimation and Development Roadmap</h3>
                    <span>Includes testing and a detailed rollout plan.</span>

                    <div className="down-icon">
                      <span className="bx bx-chevrons-down"></span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6">
                  <div className="single-industries">
                    <i className="flaticon-shield"></i>
                    <h3>Implementation and Enjoyment</h3>
                    <span>Handover and enjoy the resolution achieved by our professional team!</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="industries-img">
                <img src={offerImg} alt="Image" width={370} height={390} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResolutionRoadmap;

"use client";

import React from "react";
import ServiceSidebar from "./ServiceSidebar";
//import AskQuestionForm from "./AskQuestionForm";

import servicesDetailsImg from "../../styles/images/services-details/services-details.jpg";
import servicesDetailsImg2 from "../../styles/images/services-details/services-details2.png";
import ContactForm from "../Contact/ContactForm";
import {ourServices} from "../../libs/ourServices";
import {useParams} from "react-router-dom";

const ServiceDetailsContent: React.FC = () => {
  const {serviceId} = useParams();
  const service = ourServices.find(service=> service.id === (serviceId ? parseInt(serviceId) : 1));
  return (
    <>
      <div className="service-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-wrap">
                <div className="service-img">
                  <img
                    src={servicesDetailsImg}
                    alt="Image"
                    width={900}
                    height={500}
                  />
                </div>

                <h3>{service?.title}</h3>
                {service?.description && service.description.map((desc, index) => (
                  <p key={index}>{desc}</p>
                ))}
                {service?.steps && <div className="car-service-list-wrap">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                      <div className="car-service-list">
                        <ul>
                          {service.steps.map((step, index) => (
                            <li key={index}>
                                <i className="bx bx-check"></i>
                              <strong>{step.title}:</strong>&nbsp;{step.description}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>}
                {service?.secondarySteps && <div>
                    <p>{service.secondarySteps.description}</p>
                    <ul>
                        {service.secondarySteps.steps.map((step, index) => (
                            <li key={index}>
                                <i className="bx bx-check"></i>
                                <strong>{step.title}:</strong>&nbsp;{step.description}
                            </li>
                        ))}
                    </ul>
                </div>}
                {service?.postStepsDescription && service.postStepsDescription.map((desc, index) => (
                    <p key={index}>{desc}</p>
                ))}
              </div>
            </div>

            <div className="col-lg-4">
              <ServiceSidebar />
            </div>
            <div className="pt-70">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsContent;

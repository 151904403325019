import {Routes, Route, Navigate} from 'react-router-dom';
import Main from './pages/Main';
import ServiceDetails from "./pages/ServiceDetails";
import React from "react";

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/service-details" element={<ServiceDetails />} />
                <Route path="/service-details/:serviceId" element={<ServiceDetails />} />
            </Routes>
        </>
    );
};

export default App;
export const menus = [
    {
        label: "Home",
        link: "home",
    },
    {
        label: "About Us",
        link: "about",
    },
    {
      label: "Services", 
        link: "services",
    },
    {
        label: "Contract Models",
        link: "pricing",
    },
    {
        label: "Contact Us",
        link: "contact",
    },
];

"use client";

import React from "react";
//import Link from "next/link";
//import Image from "next/image";

import logo from "../../styles/images/white-logo-esion-horizontal.png";
import shape1 from "../../styles/images/shape/footer-shape-one.png";
import shape2 from "../../styles/images/shape/footer-shape-two.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer-top-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="single-widget"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <a href="/" className="logo">
                  <img src={logo} alt="logo" width={150} height={37} />
                </a>

                <p>
                  Your Partner in IT and Outsource Management Solutions!
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="single-widget"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <h3>Important Links</h3>

                <ul>
                  <li>
                    <a href="#about">
                      <i className="right-icon bx bx-chevrons-right"></i>
                      About Us
                    </a>
                  </li>

                  <li>
                    <a href="#contact">
                      <i className="right-icon bx bx-chevrons-right"></i>
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className="single-widget"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="500"
              >
                <h3>Information</h3>

                <ul className="information">

                  <li className="address">
                    <i className="flaticon-envelope"></i>
                    <span>Email</span>
                    <a href="mailto:info@esion.io">info@esion.io</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-shape">
          <img src={shape1} alt="Image" width={270} height={235} />
          <img src={shape2} alt="Image" width={270} height={235} />
        </div>
      </footer>

      {/* Footer Bottom Area   */}
      <footer className="footer-bottom-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="copy-right">
                <p>Copyright &copy; {currentYear} ESION. All Rights Reserved</p>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="privacy">
                {/*<ul>*/}
                {/*  <li>*/}
                {/*    <a href="/terms-conditions">Terms & Conditions</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="/privacy-policy">Privacy Policy</a>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </div>
            </div>
            
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

"use client";

import React from "react";

const EngagementModels: React.FC = () => {
  return (
    <>
      <div className="choose-ue-area">
        <div className="container">
          <div className="section-title">
            <h2>
              Engagement Models That Fit Your Business Needs
            </h2>
            {/*<p>*/}
            {/*  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nisi*/}
            {/*  rem quo itaque minus dolorem ratione vero, quisquam reiciendis*/}
            {/*  quia atque eos aspernatur.*/}
            {/*</p>*/}
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="choose-card">
                <span>
                  01 <i className="flaticon-support"></i>
                </span>
                <h3>Staff Augmentation</h3>
                <p>
                  Staff augmentation is a flexible employment strategy that allows organizations to 
                  hire top-level professionals locally or globally and manage them directly through an external service provider. 
                  This model is beneficial for companies needing rapid scalability while controlling overhead costs. 
                  ESION provides the right competency, skillset, and resources to support your needs.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="choose-card">
                <span>
                  02 <i className="flaticon-shield"></i>
                </span>
                <h3>Managed Capacity </h3>
                <p>
                  Managed capacity involves outsourcing specific repetitive or ongoing tasks to a specialized service provider like ESION. 
                  We take complete responsibility for the tasks under a contractual agreement, ensuring scalability, robustness, and efficiency 
                  while adjusting resources based on client demand. This model offers cost predictability with fixed monthly or yearly rates.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="choose-card">
                <span>
                  03 <i className="flaticon-technical-support"></i>
                </span>
                <h3>Managed Services</h3>
                <p>
                  In this model, businesses outsource part or all their IT functions to ESION, including hosting, managing, 
                  and maintaining IT infrastructure such as networking, servers, software, and cybersecurity. 
                  <br />Managed Services are governed by a service level agreement (SLA), with pricing usually based on a subscription basis for predictable IT costs.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="choose-card">
                <span>
                  04 <i className="flaticon-technical-support"></i>
                </span>
                <h3>Managed Output</h3>
                <p>
                  Managed output, also known as output-based engagement, ties payment to ESION based on actual results or outcomes delivered rather than time and resources used. 
                  ESION has flexibility in defining processes and deploying resources to achieve defined outcomes, emphasizing end results over task methods. 
                  Clear communication and outcome definition are essential for this model's success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngagementModels;

"use client";

import React from "react";
import {ourServices} from "../../libs/ourServices";

const ServiceSidebar: React.FC = () => {
  return (
    <>
      <div className="service-sidebar-area">
        <div className="service-list">
          <h3 className="service-details-title">Services</h3>
          <ul>
            {ourServices.map((ourService) => (
              <li key={ourService.id}>
                <a href={"/service-details/" + ourService.id}>
                    {ourService.title}
                  {/*<i className="bx bx-check"></i>*/}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ServiceSidebar;

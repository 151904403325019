"use client";

import React from "react";
import ContractModels from "./ContractModels";
import EngagementModels from "./EngagementModels";

const PricingStyleOne: React.FC = () => {
  return (
      <div className="pricing-area">
        <div className="container">
          <div className="section-title">
            <span>What We Offer</span>
            <h2>Contractual Models</h2>
            <p>
              These models cater to different project scenarios, allowing clients to choose based on project complexity,
              budget, and desired level of control or risk. Consider offering all three options to clients for optimal
              flexibility.
            </p>
          </div>

          <ContractModels/>
          <EngagementModels/>
        </div>
      </div>
  );
};

export default PricingStyleOne;

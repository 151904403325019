"use client";

import React from "react";

import aboutImg from "../styles/images/about-img-three.png";

const About: React.FC = () => {
  return (
    <>
      <div className="about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src={aboutImg} alt="About Us" width={375} height={440} />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <span>About Us</span>
                <h2>
                  Powerful IT Solutions Tailored to Your Needs
                </h2>
                <p>
                  Our suite of services is focused on your data transformation journey, providing an exceptional pathway for your company to become data-driven. 
                  We cover the entire data journey cycle, from designing data strategies to delivering powerful dashboards. 
                  Working closely with you, we identify your specific business needs and deliver solutions that not only meet but exceed your expectations.
                </p>
                <h5>
                  Experienced Team, Exceptional Results
                </h5>
                <p>
                  With our team of seasoned IT professionals, you gain more than just a service;
                  you gain a partnership dedicated to your company's success.
                  Our expertise is trusted by businesses worldwide, and we are committed to
                  maintaining the highest standard of quality and reliability in every project we undertake.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

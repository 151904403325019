export const contractModels = [
        {
            "title": "Time & Material",
            "features": [
                {
                    "title": "Transparency",
                    "description": "Pricing directly correlates with headcount."
                },
                {
                    "title": "Flexibility",
                    "description": "Allows project scope adjustments."
                },
                {
                    "title": "Cost-Effectiveness",
                    "description": "Pays for actual work and materials, ideal for evolving projects."
                },
                {
                    "title": "Control",
                    "description": "Clients have project oversight."
                },
                {
                    "title": "Resource Management",
                    "description": "Enables resource tracking and adjustment."
                }
            ]
        },
        {
            "title": "Time & Material with Cap",
            "features": [
                {
                    "title": "Financial Control",
                    "description": "Caps cost, ensuring budget adherence."
                },
                {
                    "title": "Quality Assurance",
                    "description": "Supports iterative development for quality outputs."
                },
                {
                    "title": "Flexibility",
                    "description": "Adjusts to changing project requirements."
                },
                {
                    "title": "Risk Reduction",
                    "description": "Guards against excessive costs."
                },
                {
                    "title": "Alignment of Interest",
                    "description": "Incentivizes efficiency within capped cost."
                },
                {
                    "title": "Transparency",
                    "description": "Tracks resource and time usage within cap."
                }
            ]
        },
        {
            "title": "Fixed Price",
            "features": [
                {
                    "title": "Budget Control",
                    "description": "Offers cost certainty from project onset."
                },
                {
                    "title": "Ease of Management",
                    "description": "Reduces client involvement in day-to-day tasks."
                },
                {
                    "title": "Risk Management",
                    "description": "Shifts project overrun risk to service provider."
                },
                {
                    "title": "Efficiency",
                    "description": "Encourages provider focus on productivity."
                },
                {
                    "title": "Clear Expectations",
                    "description": "Defines deliverables and costs upfront, minimizing disputes."
                }
            ]
        }
    ];